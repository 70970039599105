import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styled from 'styled-components';

// Define custom style object by modifying the selected theme
const customOkaidia = {
  ...okaidia,
  'code[class*="language-"]': {
    ...okaidia['code[class*="language-"]'],
    background: '#121212', // Replace with your dark mode background color
    color: '#e0e0e0', // Replace with your dark mode text color
    fontSize: '0.8rem', // Set your desired font size
    wordBreak: 'break-word', // Ensure long words break to fit the container
    whiteSpace: 'pre-wrap', // Ensure the text wraps properly
  },
  'pre[class*="language-"]': {
    ...okaidia['pre[class*="language-"]'],
    background: '#121212', // Replace with your dark mode background color
    color: '#e0e0e0', // Replace with your dark mode text color
    fontSize: '0.8rem', // Set your desired font size
    borderRadius: '10px',
    padding: '20px',
    overflow: 'auto',
    wordBreak: 'break-word', // Ensure long words break to fit the container
    whiteSpace: 'pre-wrap', // Ensure the text wraps properly
  },
};

const CodeBoxContainer = styled.div`
  margin-top: 20px;
  width: 40%;
  flex-shrink: 0;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  max-height: 500px; /* Adjust this value as needed */
`;

const CodeBox = ({ language, codeString }) => (
  <CodeBoxContainer>
    <SyntaxHighlighter language={language} style={customOkaidia}>
      {codeString}
    </SyntaxHighlighter>
  </CodeBoxContainer>
);

export default CodeBox;
