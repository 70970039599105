import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCopy, faCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import CodeBox from './CodeBox';


const Container = styled.div`
  display: flex;
  padding: 20px;
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
`;

const MainContent = styled.div`
  overflow: hidden;
  flex: 1;
  margin-right: 20px;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
`;

const SectionSubtitle = styled.h2`
  font-size: 1.25rem;
  padding-top: 5%;
  color: ${({ theme }) => theme.text};
`;

const SectionDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.tertiary};
`;

const ApiEndpointContainer = styled.div`
  background-color: ${({ theme }) => theme.dropdownBg};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiEndpointTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const MethodTag = styled.span`
  background-color: none;
  border: 0.5px solid ${({ theme }) => theme.post_color};
  color:  ${({ theme }) => theme.post_color};
  padding: 4px 9px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 10px;
`;

const EndpointPath = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: 0.9rem;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.post_color};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;

const ApiSection = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiSectionHeader = styled.div`
  padding: 15px;
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.dropdownBorder}` : 'none')};
  background-color: ${({ isOpen, theme }) => (isOpen ? theme.dropdownBg : theme.dropdownBg)};
  color: ${({ isOpen, theme }) => (isOpen ? theme.activeText : theme.text)};
  border-radius: ${({ isOpen }) => (isOpen ? '10px 10px 0 0' : '10px')};
`;

const ApiSectionBody = styled.div`
  padding: 20px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.inputBg};
  border-radius: 0 0 10px 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
`;

const InputLabelContainer = styled.div`
  display: block;
  position: absolute;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 0.75rem;
  margin-bottom: 5px;
  width: 100%;
`;

const InputLabel = styled.label`
  background: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
`;

const InputStar = styled.label`
  padding-right: 2px;
  padding-left: 4px;
  background: ${({ theme }) => theme.inputBg};
  color: red;
`;

const InputType = styled.label`
  padding-right: 2px;
  padding-left: 10px;
  background: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.primary};
`;


const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 0.75rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;

const ResponseBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
  white-space: pre-wrap; 
  overflow: auto; 
`;

const ResponseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StatusTag = styled.span`
  background-color: ${({ status, theme }) => (status >= 200 && status < 300 ? '#22C55E' : '#EF4444')};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const ResponseBody = styled.div`
  background-color: ${({ theme }) => theme.inputBg};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
`;

const ResponseText = styled.code`
  display: block;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  word-wrap: break-word;
`;

const UpdatePreferences = () => {
  const [headerOpen, setHeaderOpen] = useState(false);
  const [pathOpen, setPathOpen] = useState(false);
  const [queryOpen, setQueryOpen] = useState(false);
  const [bodyOpen, setBodyOpen] = useState(false);
  const [language, setLanguage] = useState('');
  const [traveler_type, settraveler_type] = useState('');
  const [interests, setInterests] = useState([]);
  const [text, setText] = useState('');
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [bearerToken, setBearerToken] = useState('');

  const toggleSection = (section) => {
    switch (section) {
      case 'headerOpen':
        setHeaderOpen(!headerOpen);
        break;
      case 'pathOpen':
        setPathOpen(!pathOpen);
        break;
      case 'queryOpen':
        setQueryOpen(!queryOpen);
        break;
      case 'bodyOpen':
        setBodyOpen(!bodyOpen);
        break;
      case 'bodyOpen':
        setBodyOpen(!bodyOpen);
        break;

      default:
        break;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleApiRequest = async () => {
    try {
      const response = await fetch('https://api.iwander.io/v1/update-user-preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearerToken}`,
        },
        body: JSON.stringify({
          language_code: language,
          traveler_type: traveler_type,
          interests: [interests],
        }),
      });
      const data = await response.json();
      setStatus(response.status);
      setResponse(data);
    } catch (error) {
      setStatus('error');
      setResponse({ error: 'An error occurred while making the request.' });
    }
  };

  return (
    <Container>
      <MainContent>
        <SectionTitle>Update User Preferences</SectionTitle>
        <SectionDescription>
          The Update User Preferences endpoint allows you to modify and update the preferences of an existing user within your system. By using this endpoint, you can tailor the content recommendations and travel experiences based on the user’s latest preferences.
        </SectionDescription>
        <ApiEndpointContainer>
          <ApiEndpointTitle>
            <MethodTag>POST</MethodTag>
            <EndpointPath>/v1/update-user-preferences</EndpointPath>
            <Button onClick={handleApiRequest}><FontAwesomeIcon icon={faPaperPlane} /> Send</Button>
          </ApiEndpointTitle>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('headerOpen')} isOpen={headerOpen}>
              Header
              <FontAwesomeIcon icon={headerOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={headerOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>Bearer Token</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={bearerToken} onChange={(e) => setBearerToken(e.target.value)} placeholder="Enter Bearer Token" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('bodyOpen')} isOpen={bodyOpen}>
              Body
              <FontAwesomeIcon icon={bodyOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={bodyOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>language_code</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={language} onChange={(e) => setLanguage(e.target.value)} placeholder="Enter user language" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>traveler_type</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={traveler_type} onChange={(e) => settraveler_type(e.target.value)} placeholder="Enter traveler type" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>interests</InputLabel>
                  <InputType>list</InputType>
                </InputLabelContainer>
                <Input type="text" value={interests} onChange={(e) => setInterests(e.target.value)} placeholder="Enter user interests (e.g. Art,Theatre,Sports)" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
        </ApiEndpointContainer>
        {response && (
          <ResponseBox>
            <ResponseHeader>
              <StatusTag status={status}>{status} - {status === 200 ? 'OK' : 'Error'}</StatusTag>
              <div>Body</div>
              <CopyButton onClick={() => copyToClipboard(JSON.stringify(response, null, 2))}>
                <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
              </CopyButton>
            </ResponseHeader>
            <ResponseBody>
              <ResponseText>{JSON.stringify(response, null, 2)}</ResponseText>
            </ResponseBody>
          </ResponseBox>
        )}

        <div className='specs-container'>
          <div className='section-container'>
            <div className='section-title-small'>Headers</div>
            <div className='param-container'>
              <div className='param-label'>Bearer Token<div className='required-label'>*</div> </div>
              <div className='param-description'>
                The user's bearer token. This bearer token is generated upon creation of the user by calling the /create-user endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Body</div>
            <div className='param-container'>
              <div className='param-label'>language_code</div>
              <div className='param-description'>
                The user's language. Currently this language will default to English if no language is provided.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>traveler_type</div>
              <div className='param-description'>
                The type of traveler. Options include; Single, Couple, Friends or Family.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>interests</div>
              <div className='param-description'>
                A list of user's interests. This allows users to specify their interests and adapt future content to their explicit interests.
              </div>
            </div>
          </div>
        </div>


      </MainContent>
      <div className="code-container">
        <div className="code-block-endpoints">
          <div className="line">
            <span className="command">curl</span>
            <span> </span>
            <span className="url">-request POST</span>
            <span> \</span>
          </div>
          <div className="line">
            <span> </span>
            <span className="url">https://api.iwander.io/v1/update-user-preferences</span>
            <span> \</span>
          </div>
          <div className="line">
            <span className="option">-H</span>
            <span> </span>
            <span className="hljs-string">"Authorization: Bearer </span>
            <span className="hljs-variable">{bearerToken}</span>
            <span className="hljs-string">"</span>
            <span> \</span>
          </div>
          <div className="line">
            <span className="option">-H</span>
            <span> </span>
            <span className="hljs-string">"Content-Type: </span>
            <span className="hljs-string">application/json</span>
            <span className="hljs-string">"</span>
          </div>
          <div className="line">
            <span className="option">-d</span>
            <span> </span>
            <span className="hljs-string">'{'{'}</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"language_code": "{language}",</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"traveler_type": "{traveler_type}",</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"interests": {interests},</span>
          </div>
          <div className="line">
            <span className="hljs-string">'{'}'}</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UpdatePreferences;
