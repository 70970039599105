import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faBolt, faCopy, faCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import CodeBox from './CodeBox';


const Container = styled.div`
  display: flex;
  padding: 20px;
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
`;

const MainContent = styled.div`
  overflow: hidden;
  flex: 1;
  margin-right: 20px;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
`;

const SectionSubtitle = styled.h2`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.text};
  padding-top: 5%;
`;

const SectionDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.tertiary};
`;

const ApiEndpointContainer = styled.div`
  background-color: ${({ theme }) => theme.dropdownBg};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiEndpointTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ApiEndpointFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;


const MethodTag = styled.span`
  background-color: none;
  border: 0.5px solid ${({ theme }) => theme.post_color};
  color:  ${({ theme }) => theme.post_color};
  padding: 4px 9px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 10px;
`;


const EndpointPath = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const Button = styled.button`
background-color: ${({ theme }) => theme.get_color};
color: white;
border: none;
border-radius: 5px;
padding: 5px 10px;
font-size: 1rem;
font-weight: bold;
margin-left: auto;
cursor: pointer;
display: flex;
&:hover {
  background-color: ${({ theme }) => theme.get_hover};
}`;

const WSButton = styled.button`
background-color: ${({ isConnected, theme }) => (isConnected ? theme.get_color : theme.get_color)};
color: white;
border: none;
border-radius: 5px;
padding: 5px 10px;
font-size: 1rem;
font-weight: bold;
margin-left: auto;
cursor: pointer;
display: flex;
&:hover {
  background-color: ${({ theme }) => theme.get_hover};
}`;

const ApiSection = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiSectionHeader = styled.div`
  padding: 15px;
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.dropdownBorder}` : 'none')};
  background-color: ${({ isOpen, theme }) => (isOpen ? theme.dropdownBg : theme.dropdownBg)};
  color: ${({ isOpen, theme }) => (isOpen ? theme.activeText : theme.text)};
  border-radius: ${({ isOpen }) => (isOpen ? '10px 10px 0 0' : '10px')};
`;

const ApiSectionBody = styled.div`
  padding: 20px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.inputBg};
  border-radius: 0 0 10px 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
`;

const InputLabelContainer = styled.div`
  display: block;
  position: absolute;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 0.75rem;
  margin-bottom: 5px;
  width: 100%;
`;

const InputLabel = styled.label`
  background: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
`;

const InputStar = styled.label`
  padding-right: 2px;
  padding-left: 4px;
  background: ${({ theme }) => theme.inputBg};
  color: red;
`;


const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 0.75rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;


const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;

const ResponseBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
  white-space: pre-wrap; 
  overflow: auto; 
`;

const ResponseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StatusTag = styled.span`
  background-color: ${({ status, theme }) => (status == "Connected" ? '#22C55E' : '#EF4444')};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const ResponseBody = styled.div`
  background-color: ${({ theme }) => theme.inputBg};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  max-height:500px;
  overflow: scroll;
`;

const ResponseText = styled.code`
  display: block;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  word-wrap: break-word;
`;

const TextWebsSocket = () => {
  const [headerOpen, setHeaderOpen] = useState(false);
  const [pathOpen, setPathOpen] = useState(false);
  const [guide_id, setGuide] = useState('');
  const [bodyOpen, setBodyOpen] = useState(false);
  const [location, setLocation] = useState('');
  const [session_id, setSessionID] = useState('');
  const [user_query, setQuery] = useState('');
  const [response, setResponse] = useState(["Initialise Websocket Connection"]);
  const [status, setStatus] = useState("Disconnected");
  const [isCopied, setIsCopied] = useState(false);
  const [bearerToken, setBearerToken] = useState('');
  const [websocketUrl, setWebsocketUrl] = useState('wss://api.iwander.io/v1/text-generation/{guide_id}/ws?token=');
  const [websocket, setWebsocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);


  const toggleSection = (section) => {
    switch (section) {
      case 'headerOpen':
        setHeaderOpen(!headerOpen);
        break;
      case 'pathOpen':
        setPathOpen(!pathOpen);
        break;
      case 'bodyOpen':
        setBodyOpen(!bodyOpen);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const url = (bearerToken && guide_id)
      ? `wss://api.iwander.io/v1/text-generation/${guide_id}/ws?token=${bearerToken}`
      : `wss://api.iwander.io/v1/text-generation/{guide_id}/ws?token=${bearerToken}`;

    setWebsocketUrl(url);
  }, [bearerToken, guide_id]);


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const sendWebSocketMessage = () => {
    if (isConnected) {
      const messageData = {
        session_id: session_id,
        user_query: user_query,
        location: location
      };
      websocket.send(JSON.stringify(messageData));
    }
  };

  const handleWebSocketConnect = () => {
    if (isConnected) {
      // If connected, close the WebSocket
      if (websocket) {
        websocket.close();
        setWebsocket(null);
        setIsConnected(false);
        setStatus('Disconnected');
      }
    } else {
      // If not connected, establish a new WebSocket connection
      const ws = new WebSocket(websocketUrl);

      ws.onopen = () => {
        setIsConnected(true);
        setStatus('Connected');
        setResponse(['Send message']);
        console.log('WebSocket connected');
      };

      ws.onmessage = (event) => {
        const message = event.data;
        setResponse((prev) => [...prev, message]);
        console.log('Received message:', message);
      };

      ws.onerror = (error) => {
        setStatus('Error');
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        setIsConnected(false);
        setStatus('Disconnected');
        console.log('WebSocket disconnected');
      };

      setWebsocket(ws);
    }
  };



  return (
    <Container>
      <MainContent>
        <SectionTitle>Text Generation Websocket</SectionTitle>
        <SectionDescription>
          This websocket allows you to create personalized travel content in real-time. It is advised to use this websocket when
          you are planning on implementing the audio websocket. If you are not planning on implementing the audio websocket,
          we recommend using the /text-generation POST endpoint.
        </SectionDescription>
        <ApiEndpointContainer>
          <ApiEndpointTitle>
            <FontAwesomeIcon icon={faBolt} style={{ marginRight: '10px', minWidth: '35px', textAlign: 'center', paddingRight: '5px', paddingLeft: '5px' , color: isConnected ? '#2ab673' : 'white'}} />
            <EndpointPath>
              {websocketUrl}
            </EndpointPath>
            <WSButton onClick={handleWebSocketConnect}
            ><FontAwesomeIcon icon={faBolt} style={{ marginRight: '5px'}} />{isConnected ? ' Disconnect' : ' Connect'}</WSButton>
          </ApiEndpointTitle>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('headerOpen')} isOpen={headerOpen}>
              Query
              <FontAwesomeIcon icon={headerOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={headerOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>Bearer Token</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={bearerToken} onChange={(e) => setBearerToken(e.target.value)} placeholder="Enter Bearer Token" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('pathOpen')} isOpen={pathOpen}>
              Path
              <FontAwesomeIcon icon={pathOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={pathOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>guide_id:</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={guide_id} onChange={(e) => setGuide(e.target.value)} placeholder="Enter guide_id" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('bodyOpen')} isOpen={bodyOpen}>
              Body
              <FontAwesomeIcon icon={bodyOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={bodyOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>user_query</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={user_query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter user query" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>location</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Enter user location (lat,lon)" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>session_id</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={session_id} onChange={(e) => setSessionID(e.target.value)} placeholder="Enter session_id" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiEndpointFooter>
            <Button onClick={sendWebSocketMessage}><FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px'}}/> Send</Button>
          </ApiEndpointFooter>
        </ApiEndpointContainer>
        <SectionSubtitle>Instructions</SectionSubtitle>
        <SectionDescription>
          Open the websocket by specifying the Bearer Token and the guide_id. Once connected, send your request body to start generating content.
        </SectionDescription>
        <SectionDescription>
          In order to initialise a new chat session, send a requqest with an empty session_id. The response will then provide you with a session_id, that
          should be sent in any subsequent requests within the same session. This is important to ensure a continuation of the conversation.
        </SectionDescription>

        <div className='specs-container'>
          <div className='section-container'>
            <div className='section-title-small'>Query</div>
            <div className='param-container'>
              <div className='param-label'>Bearer Token<div className='required-label'>*</div> </div>
              <div className='param-description'>
                The user's bearer token. This bearer token is generated upon creation of the user by calling the /create-user endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Path</div>
            <div className='param-container'>
              <div className='param-label'>guide_id  <div className='required-label'>*</div> </div>
              <div className='param-description'>
                The guide_id to be used. You can view all guide_id options by calling the /guides endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Body</div>
            <div className='param-container'>
              <div className='param-label'>user_query<div className='required-label'>*</div></div>
              <div className='param-description'>
              The user query.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>location</div>
              <div className='param-description'>
                The user's current location, specified as latitude,longitude (e.g. 51.5007,-0.1246).
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>session_id</div>
              <div className='param-description'>
                The current session_id, if existing. If it is the beginning of a new interaction, send a blank session_id. The response will include a unique session_id associated to this new sessions.
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <div className="ws-container">
      <SectionSubtitle>Response Sample</SectionSubtitle>
        {status != null && (
          <ResponseBox>
            <ResponseHeader>
              <StatusTag status={status}>{status}</StatusTag>
              <CopyButton onClick={() => copyToClipboard(response.join(''))}>
                <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
              </CopyButton>
            </ResponseHeader>
            <ResponseBody>
              <ResponseText>{response.join('\n')}</ResponseText>
            </ResponseBody>
          </ResponseBox>
        )}
      </div>
    </Container>
  );
};

export default TextWebsSocket;
