import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCode } from '@fortawesome/free-solid-svg-icons';

const SidebarContainer = styled.div`
  width: 18rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.sidebarText};
  padding: 20px;
  nargin-bottom: 40px;
  box-sizing: border-box;
  overflow: scroll;
`;

const SidebarTitle = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left:15px;
`;

const SidebarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: ${({ theme }) => theme.sidebarText};
  text-decoration: none;
  font-size: 0.8rem;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.sidebarHover};
  }

  &.active {
    background-color: ${({ theme }) => theme.activeBg};
    color: ${({ theme }) => theme.activeText};
  }
`;

const MethodTagPost = styled.span`
  background-color: none;
  border: 1px solid ${({ theme }) => theme.post_color};
  color:  ${({ theme }) => theme.post_color};
  padding: 2px 5px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 0.75rem;
  margin-right: 10px;
  width: 35px;
  text-align: center;
`;

const MethodTagGet = styled.span`
  background-color: none;
  border: 1px solid ${({ theme }) => theme.get_color};
  color:  ${({ theme }) => theme.get_color};
  padding: 2px 5px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 0.75rem;
  margin-right: 10px;
  width: 35px;
  text-align: center;
`;

const Sidebar = () => {
  return (
    <SidebarContainer>
      <SidebarTitle>API Reference</SidebarTitle>
      <SidebarLink to="/docs/api-reference/introduction">
        <FontAwesomeIcon icon={faCode} style={{ marginRight: '10px', width: '35px', textAlign: 'center', paddingRight: '5px', paddingLeft: '5px'}} />
        Introduction
      </SidebarLink>

      <SidebarTitle>Users</SidebarTitle>
      <SidebarLink to="/docs/api-reference/create-user">
        <MethodTagGet>GET</MethodTagGet>
        Create User
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/delete-user">
        <MethodTagPost>POST</MethodTagPost>
        Delete User
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/update-user-preferences">
        <MethodTagPost>POST</MethodTagPost>
        Update User Preferences
      </SidebarLink>

      <SidebarTitle>Text Generation</SidebarTitle>
      <SidebarLink to="/docs/api-reference/text-generation">
        <MethodTagPost>POST</MethodTagPost>
        Text Generation
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/image-recognition">
        <MethodTagPost>POST</MethodTagPost>
        Image Recognition
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/recommended-next-stops">
        <MethodTagPost>POST</MethodTagPost>
        Recommended Next Stops
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/text-generation-websocket">
        <FontAwesomeIcon icon={faBolt} style={{ marginRight: '10px', width: '35px', textAlign: 'center', paddingRight: '5px', paddingLeft: '5px'}} />
        Text Websocket
      </SidebarLink>

      <SidebarTitle>Audio Generation</SidebarTitle>
      <SidebarLink to="/docs/api-reference/audio-generation">
        <MethodTagPost>POST</MethodTagPost>
        Audio Generation
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/audio-generation-stream">
        <MethodTagPost>POST</MethodTagPost>
        Audio Stream
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/audio-generation-websocket">
        <FontAwesomeIcon icon={faBolt} style={{ marginRight: '10px', width: '35px', textAlign: 'center', paddingRight: '5px', paddingLeft: '5px'}} />
        Audio Websocket
      </SidebarLink>

      <SidebarTitle>Tickets</SidebarTitle>
      <SidebarLink to="/docs/api-reference/tickets">
        <MethodTagPost>POST</MethodTagPost>
        Tickets
      </SidebarLink>

      <SidebarTitle>History</SidebarTitle>
      <SidebarLink to="/docs/api-reference/user-sessions">
        <MethodTagGet>GET</MethodTagGet>
        User Sessions
      </SidebarLink>
      <SidebarLink to="/docs/api-reference/user-session-by-id">
        <MethodTagPost>POST</MethodTagPost>
        User Session by ID
      </SidebarLink>

      <SidebarTitle>Guides</SidebarTitle>
      <SidebarLink to="/docs/api-reference/guides">
        <MethodTagGet>GET</MethodTagGet>
        Guides
      </SidebarLink>
      {/* <SidebarLink to="/docs/api-reference/voices">
        <MethodTagGet>GET</MethodTagGet>
        Voices
      </SidebarLink> */}

    </SidebarContainer>
  );
};

export default Sidebar;
