import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.body};
`;

const LoginBox = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.body};
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  box-sizing: border-box; // Ensure the padding and border are included in the element's width
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.post_color};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 20px; // Ensure space between the button and other elements
  box-sizing: border-box; // Ensure the padding and border are included in the element's width

  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  gap: 5px;
`;

const Tab = styled.button`
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.post_color : theme.body)};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [organisationId, setOrganisationId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState('login');
  const navigate = useNavigate();

  // Helper function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate login fields
  const validateLoginFields = () => {
    if (!email || !password) {
      setErrorMessage('Please fill out all fields.');
      return false;
    }
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters.');
      return false;
    }
    return true;
  };

  // Validate signup fields
  const validateSignUpFields = () => {
    if (!email || !password || !userName || !organisationId) {
      setErrorMessage('Please fill out all fields.');
      return false;
    }
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters.');
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    if (!validateLoginFields()) return; // If validation fails, exit the function

    try {
      const response = await fetch('https://platform.iwander.io/external/verify-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('organisation_id', data.organisation_id);
        onLogin(data.token); 
        navigate('/docs/api-reference');
      } else {
        setErrorMessage(data.message || 'Invalid login credentials');
      }
    } catch (error) {
      setErrorMessage('An error occurred while logging in');
    }
  };

  const handleSignUp = async () => {
    if (!validateSignUpFields()) return; // If validation fails, exit the function

    try {
      const response = await fetch('https://platform.iwander.io/external/user-creation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: email,
          user_name: userName,
          password,
          organisation_id: organisationId,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('organisation_id', data.organisation_id);
        onLogin(data.token);
        navigate('/docs/api-reference');
      } else {
        setErrorMessage(data.message || 'Sign up failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred while signing up');
    }
  };

  return (
    <Container>
      <LoginBox>
        <TabContainer>
          <Tab active={activeTab === 'login'} onClick={() => setActiveTab('login')}>Login</Tab>
          <Tab active={activeTab === 'signup'} onClick={() => setActiveTab('signup')}>Sign Up</Tab>
        </TabContainer>

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        {activeTab === 'login' ? (
          <>
            <Title>Login</Title>
            <Input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={handleLogin}>Login</Button>
          </>
        ) : (
          <>
            <Title>Sign Up</Title>
            <Input
              type="text"
              placeholder="Enter organisation ID"
              value={organisationId}
              onChange={(e) => setOrganisationId(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Enter name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={handleSignUp}>Create Account</Button>
          </>
        )}
      </LoginBox>
    </Container>
  );
};

export default Login;