import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import 'leaflet.heat';
import 'leaflet/dist/leaflet.css';

const Heatmap = ({
    heatmapData
    }) => {
    const mapRef = useRef(null);
    const heatLayerRef = useRef(null); // Reference to the heat layer

    useEffect(() => {
        // Ensure map is initialized only once
        if (mapRef.current === null) {
            mapRef.current = L.map('heatmap', {
                center: [20, 0], // Center of the map
                zoom: 2,
                layers: [
                    L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
                        attribution: '&copy; OpenStreetMap contributors',
                    }),
                ],
            });
        }

        // Add or update the heatmap layer whenever `data` changes
        if (heatLayerRef.current) {
            mapRef.current.removeLayer(heatLayerRef.current); // Remove the previous heat layer
        }

        heatLayerRef.current = L.heatLayer(heatmapData, {
            radius: 35, // Radius of influence
            blur: 20, // Blur intensity
            maxZoom: 10, // Maximum zoom level
            gradient: {
                0.1: 'blue',
                0.3: 'cyan',
                0.5: 'lime',
                0.7: 'yellow',
                1.0: 'green',
            },
            max: 1, 
        });

        heatLayerRef.current.addTo(mapRef.current);
    }, [heatmapData]); // Re-run this effect whenever `data` changes

    return <div id="heatmap" style={{ height: '600px', width: '60%', borderRadius: '20px' }} />;
};

export default Heatmap;