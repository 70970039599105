import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import YourDetails from './YourDetails';
import APIKeys from './APIKeys';
import Users from './Users';

const StyledBox = styled(Box)`
  width: 80%;
  margin: 2% 5%;
  color: ${({ theme }) => theme.text}; /* Theme-based text color */
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
`;

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.secondaryText} !important;
  font-size: 16px !important;
  text-transform: none !important;

  &:hover {
    color: ${({ theme }) => theme.text} !important; 
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.primary} !important; 
  }
`;

const ProfileTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Determine the active tab based on the URL parameter
  const currentTab = searchParams.get('tab'); 
  const [activeTab, setActiveTab] = useState(currentTab);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchParams({ tab: newValue });
  };

  // Perform API calls only when the tab changes, without resetting the activeTab state unnecessarily
  useEffect(() => {
    // Example API call when switching tabs
    if (activeTab === 'profile') {
      console.log('Fetching details data...');
    } else if (activeTab === 'api-keys') {
      console.log('Fetching API keys...');
    } else if (activeTab === 'users') {
      console.log('Fetching users...');
    }
  }, [activeTab]); // Only re-run the effect when `activeTab` changes

  return (
    <StyledBox>
      <SectionTitle>Your Organisation</SectionTitle>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <StyledTab value="profile" label="Profile" />
        <StyledTab value="api-keys" label="API Keys" />
        <StyledTab value="users" label="Users" />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ marginTop: '20px', color: 'inherit' }}>
        {activeTab === 'profile' && <YourDetails />}
        {activeTab === 'api-keys' && <APIKeys />}
        {activeTab === 'users' && <Users />}
      </Box>
    </StyledBox>
  );
};

export default ProfileTabs;