import styled from 'styled-components';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { faEye, faEyeSlash, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './Introduction.css'

const Container = styled.div`
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 50px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const SectionParagraph = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.text};
`;


const ListItem = styled.li`
  margin-bottom: 10px;
`;

const InlineCode = styled.code`
  background-color: ${({ theme }) => theme.inputBg};
  padding: 2px 5px;
  border-radius: 5px;
`;

const ErrorList = styled.ul`
  margin-left: 20px;
`;

const ErrorItem = styled.li`
  margin-bottom: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.inputBg};
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
`;

const TableCell = styled.td`
  padding: 10px;
  color: ${({ theme }) => theme.text};
`;


const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${({ theme }) => theme.text};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const ApiDocumentation = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [codeID, setcodeID] = useState([]);

  const handleCopy = (codeID) => {
    navigator.clipboard.writeText(codeID);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Show "Copied!" for 2 seconds
    };

  return (
    <Container>
      <SectionTitle>iWander API Documentation</SectionTitle>

      <SectionParagraph>Welcome to the iWander API! This API enables you to create hyper-personalized, relevant, and accurate travel content for your users. By leveraging the iWander API, you can enrich your platform with tailored travel recommendations, user-generated preferences, and personalized content that evolves with each user's journey.</SectionParagraph>

      <SubTitle>Getting Started</SubTitle>
      <SectionParagraph>The iWander API is built on REST principles and is accessible via standard HTTP methods like <InlineCode>GET</InlineCode>, <InlineCode>POST</InlineCode>, <InlineCode>PUT</InlineCode>, and <InlineCode>DELETE</InlineCode>. All API requests must be made over HTTPS for security reasons.</SectionParagraph>
      <SectionParagraph>The iWander API also offers a websocket solution, which facilitates more complex streaming to streaming use cases.</SectionParagraph>

      <SubTitle>Base URL</SubTitle>
      <SectionParagraph>All requests to the iWander API should be made to the following base URL:</SectionParagraph>
      <div className="code-block">
        <button className="copy-button" onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} /> {/* FontAwesome Copy icon */}
        </button>
        <div className="line">
          <span> </span>
          <span className="url">https://api.iwander.io/v1/</span>
        </div>
      </div>

      <SubTitle>Authentication</SubTitle>
      <SectionParagraph>The iWander API uses two types of authentication:</SectionParagraph>
      <ul>
        <ListItem><strong>API Key Authentication:</strong> Used for creating and managing users.</ListItem>
        <ListItem><strong>User-Specific Bearer Tokens:</strong> Used for personalizing content and managing individual user preferences.</ListItem>
      </ul>

      <SubTitle>Using the API Key</SubTitle>
      <SectionParagraph>To make requests, include the API key as a field in the request headers:</SectionParagraph>
      <div className="code-block">
        <div className="line">
          <span> </span>
          <span className="url">api-key: YOUR_API_KEY</span>
        </div>
      </div>


      <SectionParagraph>Example `GET` request to create a new user:</SectionParagraph>
      <div className="code-block">
      <div className="line">
          <span className="command">curl</span>
          <span> </span>
          <span className="url">-request GET</span>
          <span> \</span>
        </div>
        <div className="line">
          <span> </span>
          <span className="url">https://api.iwander.io/v1/create-user</span>
          <span> \</span>
        </div>
        <div className="line">
          <span className="option">-H</span>
          <span> </span>
          <span className="hljs-string">"api-key: </span>
          <span className="hljs-variable">$IWANDER_API_KEY</span>
          <span className="hljs-string">"</span>
          <span> \</span>
        </div>
        <div className="line">
          <span className="option">-H</span>
          <span> </span>
          <span className="hljs-string">"Content-Type: </span>
          <span className="hljs-string">application/json</span>
          <span className="hljs-string">"</span>
        </div>
      </div>

      <SubTitle>User-Specific Bearer Tokens</SubTitle>
      <SectionParagraph>Once a user has been created, a unique Bearer token will be returned for that user. Use this token for all subsequent requests involving personalized content.</SectionParagraph>
      <div className="code-block">
        <div className="line">
          <span> </span>
          <span className="url">Authorization: Bearer IWANDER_BEARER_TOKEN</span>
        </div>
      </div>
      <SectionParagraph>Example `POST` request to create personalised travel content for an individual user:</SectionParagraph>
      
      <div className="code-block">
        <div className="line">
          <span className="command">curl</span>
          <span> </span>
          <span className="url">-request POST</span>
          <span> \</span>
        </div>
        <div className="line">
          <span> </span>
          <span className="url">https://api.iwander.io/v1/text-generation</span>
          <span> \</span>
        </div>
        <div className="line">
          <span className="option">-H</span>
          <span> </span>
          <span className="hljs-string">"Authorization: Bearer </span>
          <span className="hljs-variable">$IWANDER_BEARER_TOKEN</span>
          <span className="hljs-string">"</span>
          <span> \</span>
        </div>
        <div className="line">
          <span className="option">-H</span>
          <span> </span>
          <span className="hljs-string">"Content-Type: </span>
          <span className="hljs-string">application/json</span>
          <span className="hljs-string">"</span>
        </div>
      </div>

      <SubTitle>Error Handling</SubTitle>
      <SectionParagraph>Errors are returned with standard HTTP response codes:</SectionParagraph>
      <ErrorList>
        <ErrorItem><strong>200 OK:</strong> The request was successful.</ErrorItem>
        <ErrorItem><strong>400 Bad Request:</strong> The request was invalid.</ErrorItem>
        <ErrorItem><strong>401 Unauthorized:</strong> Missing or invalid API key or Bearer token.</ErrorItem>
        <ErrorItem><strong>403 Forbidden:</strong> You do not have permission for the requested resource.</ErrorItem>
        <ErrorItem><strong>404 Not Found:</strong> The requested resource could not be found.</ErrorItem>
        <ErrorItem><strong>500 Internal Server Error:</strong> A problem on our end, try again later.</ErrorItem>
      </ErrorList>

      {/* <SubTitle>Rate Limiting</SubTitle>
      <SectionParagraph>The iWander API is subject to rate limits of 1,000 requests per minute. If you exceed this limit, you will receive a <InlineCode>429 Too Many Requests</InlineCode> error. Rate limit information is provided in the response headers:</SectionParagraph>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Header</TableHeader>
            <TableHeader>Description</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableCell><InlineCode>X-RateLimit-Limit</InlineCode></TableCell>
            <TableCell>Maximum requests allowed per minute (e.g., 1000).</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><InlineCode>X-RateLimit-Remaining</InlineCode></TableCell>
            <TableCell>Remaining requests available.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><InlineCode>X-RateLimit-Reset</InlineCode></TableCell>
            <TableCell>Time when the rate limit will reset.</TableCell>
          </TableRow>
        </tbody>
      </Table> */}

      {/* <SubTitle>API Response Format</SubTitle>
      <SectionParagraph>All responses are returned in JSON format. A typical response for creating a user might look like:</SectionParagraph>
      <CodeSnippet>
      </CodeSnippet> */}

      <SubTitle>Available Endpoints</SubTitle>
      <ul>
        <ListItem><strong>Users:</strong> Create, delete, and update user preferences.</ListItem>
        <ListItem><strong>Text Generation:</strong> Generate personalized travel content for users.</ListItem>
        <ListItem><strong>Audio Generation:</strong> Generate and stream personalized travel audio content.</ListItem>
        <ListItem><strong>History:</strong> Retrieve user sessions.</ListItem>
        <ListItem><strong>Guides & Voices:</strong> Retrieve available guide personas and voice options.</ListItem>
      </ul>
    </Container>
  );
};

export default ApiDocumentation;