import React from 'react';
import { Navigate } from 'react-router-dom';

// Protected Route component that checks if user is authenticated
const ProtectedRoute = ({ children }) => {
  const authToken = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');

  if (!authToken) {
    // If no authToken, redirect to login
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected content
  return children;
};

export default ProtectedRoute;