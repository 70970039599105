import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCopy } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  max-width: 100%;
  padding-left: 16px;
  padding-top: 10px;
  border-radius: 8px;
  color: ${({ theme }) => theme.text};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const TableHeader = styled.th`
  padding: 10px;
  color: ${({ theme }) => theme.text};
  text-align: left;
`;

const TableCell = styled.td`
  padding: 10px;
  color: ${({ theme }) => theme.text};
`;

const KeyContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.inputBg};
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
`;

const KeyText = styled.span`
  flex-grow: 1;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  letter-spacing: 2px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: ${({ theme }) => theme.text};

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  gap: 5px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.post_color};
  color: white;
  flex: 1;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;

const CancelButton = styled.button`
  color: black;
  flex: 1;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.body};
  padding: 20px;
  border-radius: 8px;
  width: 400px;
`;

const ModalTitle = styled.h3`
  text-align: center;
  margin-bottom: 20px;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.inputBg};
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
`;

const APIKeys = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [showKeyMap, setShowKeyMap] = useState({});
  const [copiedKeyId, setCopiedKeyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [apiKeyName, setApiKeyName] = useState(''); // State for API key name input
  const [errorMessage, setErrorMessage] = useState(''); // State for form validation

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  // Fetch API keys from the backend
  useEffect(() => {
    const fetchApiKeys = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch('https://platform.iwander.io/external/get-api-keys', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch API keys');
        }

        const data = await response.json();
        setApiKeys(data);
      } catch (error) {
        console.error('Error fetching API keys:', error);
      }
    };

    fetchApiKeys();
  }, []);

  const handleToggleShowKey = (keyId) => {
    setShowKeyMap((prev) => ({
      ...prev,
      [keyId]: !prev[keyId],
    }));
  };

  const handleCopy = (keyId, apiKey) => {
    navigator.clipboard.writeText(apiKey);
    setCopiedKeyId(keyId);
    setTimeout(() => setCopiedKeyId(null), 2000);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setApiKeyName(''); // Clear input when closing modal
    setErrorMessage(''); // Clear error message
  };

  const handleCreateApiKey = async () => {
    if (!apiKeyName) {
      setErrorMessage('Please enter a name for the API key.');
      return;
    }

    const organisationId = localStorage.getItem('organisation_id'); 
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch('https://platform.iwander.io/external/create-api-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          organisation_id: organisationId,
          api_key_name: apiKeyName,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create API key');
      }

      const data = await response.json();
      
      // Update API keys list with the newly created API key
      setApiKeys((prevApiKeys) => [...prevApiKeys, data]);
      handleCloseModal(); // Close the modal on success
    } catch (error) {
      console.error('Error creating API key:', error);
      setErrorMessage('Error creating API key.');
    }
  };

  return (
    <Container>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>API Key Name</TableHeader>
            <TableHeader>Date Generated</TableHeader>
            <TableHeader>API Key</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {apiKeys.map((key, index) => (
            <TableRow key={index}>
              <TableCell>{key.api_key_name}</TableCell>
              <TableCell>{formatDate(key.generated_at)}</TableCell>
              <TableCell>
                <KeyContainer>
                  <KeyText>{showKeyMap[key.api_key] ? key.api_key : '••••••••••••••••••••••••••'}</KeyText>
                  <IconButton onClick={() => handleToggleShowKey(key.api_key)}>
                    <FontAwesomeIcon icon={showKeyMap[key.api_key] ? faEyeSlash : faEye} />
                  </IconButton>
                  <IconButton onClick={() => handleCopy(key.api_key, key.api_key)}>
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                  {copiedKeyId === key.api_key && (
                    <span style={{ color: '#A8DADC', marginLeft: '10px' }}>Copied</span>
                  )}
                </KeyContainer>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>

      <Button style={{ backgroundColor: '#2979ff' }} onClick={handleOpenModal}>
        Generate Additional API Key
      </Button>

      {/* Modal for creating new API key */}
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Create New API Key</ModalTitle>
            <ModalInput
              type="text"
              placeholder="Enter API key name"
              value={apiKeyName}
              onChange={(e) => setApiKeyName(e.target.value)}
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <ButtonContainer>
              <Button onClick={handleCreateApiKey}>Create</Button>
              <CancelButton onClick={handleCloseModal}>
                Cancel
              </CancelButton>
            </ButtonContainer>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default APIKeys;