import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';

// Styled container
const MainContainer = styled.div`
  display: flex;
  gap: 20px;
  background-color: ${({ theme }) => theme.background};
  height: 80vh;
`;

// Left section for the table
const TableContainer = styled.div`
  flex: 4;
  background-color: ${({ theme }) => theme.cardBackground};
  overflow-x: auto; /* Enables horizontal scrolling */
  padding: 0px 20px;
`;


// Styled table
const StyledTable = styled.table`
    width: 100%;
    min-width: 1000px; /* Ensures table doesn't shrink below this width */
    border-collapse: collapse;
    text-align: left;

    thead {
        position: sticky;
        top: 0; /* Sticks the header to the top of the table */
        z-index: 2; /* Ensure it appears above other elements */
      }
    
  th {
    padding: 12px 15px;
    height: 25px; /* Ensures uniform row height */
    vertical-align: middle; /* Align text vertically to the middle */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis to the end of the content */
    max-width: 300px; /* Adjust width for ellipsis effect */  
    background-color: ${({ theme }) => theme.tableHeader};
    color: #a0a0ab;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  td {
    vertical-align: middle; /* Align text vertically to the middle */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis to the end of the content */
    max-width: 300px; /* Adjust width for ellipsis effect */  
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 1rem;
  }

  tr {
    &:hover {
        background-color: ${({ theme }) => theme.tableHeader};
      }    
      border-bottom: 0.5px solid ${({ theme }) => theme.border};


  }

`;

const PlaceholderStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
  }

  .stat-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;
const TopRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const ExportButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const exportToCSV = (data, filename = `users_data_${new Date().toISOString().split('T')[0]}.csv`) => {
  if (!data || data.length === 0) {
    console.error('No data available to export');
    return;
  }

  // Extract headers from the data keys
  const headers = Object.keys(data[0]);

  // Create CSV rows with proper formatting
  const rows = data.map((row) =>
    headers.map((header) => {
      const value = row[header] || ''; // Handle missing values

      // Handle dictionaries, arrays, and strings
      const formattedValue =
        typeof value === 'object' && value !== null
          ? `"${JSON.stringify(value).replace(/"/g, '""')}"` // Serialize and escape dictionaries
          : typeof value === 'string'
            ? `"${value.replace(/"/g, '""')}"` // Escape strings
            : Array.isArray(value)
              ? `"${value.join(', ').replace(/"/g, '""')}"` // Handle arrays
              : `"${value}"`; // Default formatting for other types

      return formattedValue;
    }).join(',')
  );

  // Combine headers and rows
  const csvContent = [headers.join(','), ...rows].join('\n');

  // Create a Blob and download the file
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const AllUsersTable = () => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(600); // Default width of 400px
  const [isResizing, setIsResizing] = useState(false); // Track resizing state

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      const organisationId = localStorage.getItem('organisation_id'); 
      try {
        const response = await fetch('https://platform.iwander.io/analytics/organisation-users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ organisation_id:organisationId }),
        });
        const result = await response.json();

        // Transform data for the table
        const transformedData = result.map((item) => ({
          user_created_at: new Date(item.user_created_at).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          }),
          user_id: item.user_id,
          organisation_id: item.api_key,
          language: item.language,
          inferred_information: item.context,
          latest_updated: new Date(item.latest_updated).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          }),
          traveler_type: item.traveler_type,
          user_interest: item.user_interest,
          user_preferences: item.user_preferences,
        }));

        setData(transformedData);
      } catch (error) {
        console.error('Error fetching detailed requests:', error);
      }
    };

    fetchData();
  }, []);

  // Define table columns
  const columns = React.useMemo(
    () => [
      { Header: 'User Created At', accessor: 'user_created_at' },
      { Header: 'User ID', accessor: 'user_id' },
      { Header: 'Organisation ID', accessor: 'organisation_id' },
      { Header: 'Language', accessor: 'language' },
      {
        Header: 'Inferred Information', // Column for the context field
        accessor: 'inferred_information', // Access the context field
        Cell: ({ value }) => {
          if (!value) return 'N/A'; // Handle missing context

          // Dynamically render the context dictionary
          return (
            <div style={{ fontSize: '0.9rem', whiteSpace: 'nowrap', lineHeight: '1.5' }}>
              {Object.entries(value).map(([key, subValue]) => (
                <div key={key} style={{ marginBottom: '10px' }}>
                  <strong>{key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}:</strong>
                  <ul style={{ paddingLeft: '20px', marginTop: '5px' }}>
                    {typeof subValue === 'object' && subValue !== null
                      ? Object.entries(subValue).map(([subKey, items]) => (
                        <li key={subKey}>
                          <strong>
                            {subKey
                              .replace(/_/g, ' ')
                              .replace(/\b\w/g, (char) => char.toUpperCase())}:
                          </strong>
                          <ul style={{ paddingLeft: '20px' }}>
                            {Array.isArray(items)
                              ? items.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))
                              : items}
                          </ul>
                        </li>
                      ))
                      : subValue}
                  </ul>
                </div>
              ))}
            </div>
          );
        },
      },
      { Header: 'Latest Request', accessor: 'latest_updated' },],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleMouseDown = () => setIsResizing(true); // Start resizing
  const handleMouseUp = () => setIsResizing(false); // Stop resizing
  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = Math.max(600, Math.min(1000, window.innerWidth - e.clientX));
      setSidebarWidth(newWidth);
    }
  };

  useEffect(() => {
    // Attach event listeners for dragging
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);


  return (
    <MainContainer>
      {/* Table Section */}
      <TableContainer>
        <TopRightContainer>
          <ExportButton onClick={() => exportToCSV(data)}>Export to CSV</ExportButton>
        </TopRightContainer>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>
    </MainContainer>
  );
};

export default AllUsersTable;