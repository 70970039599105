import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-left: 16px;
  padding-top: 10px;
  border-radius: 8px;
  color: ${({ theme }) => theme.text};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  padding: 8px;
  width: 300px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 10px;
  border: none;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.get_color};
  color: white;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBg};
  }
`;

const UserList = styled.div`
  border-radius: 10px;
  margin-bottom: 20px;
  height: 450px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 8px;
`;

const UserItem = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 15%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.inputBorder};

  &:last-child {
    border-bottom: none;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  margin-right: 10px;
`;

const UserText = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
`;

const UserEmail = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.text};
`;

const RoleDropdown = styled.select`
  padding: 5px;
  border-radius: 8px;
  max-width: 9rem;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.inputBorder};
`;

const ActionButton = styled.button`
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBg};
  }
`;


const Users = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch('https://platform.iwander.io/external/get-org-users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const data = await response.json();
        setUsers(data); // Set the users in state
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(user =>
    user.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.user_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      {/* Header */}
      <Header>
        <SearchBar
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* <ButtonContainer>
          <Button primary>+ Invite</Button>
        </ButtonContainer> */}
      </Header>

      {/* Loading and Error States */}
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}

      {/* User List */}
      {!loading && !error && (
        <UserList>
          {filteredUsers.map((user, index) => (
            <UserItem key={index}>
              <UserInfo>
                <Avatar>{user.user_name[0]}</Avatar>
                <UserText>
                  <UserName>
                    {user.user_name}
                  </UserName>
                  <UserEmail>{user.user_email}</UserEmail>
                </UserText>
              </UserInfo>
              <RoleDropdown value={user.role}>
                <option value="Owner">Owner</option>
                <option value="Admin">Admin</option>
                <option value="Member">Member</option>
              </RoleDropdown>
            </UserItem>
          ))}
        </UserList>
      )}
    </Container>
  );
};

export default Users;