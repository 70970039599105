import React, { useState } from "react";
import styled from "styled-components";

const TimeFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
`;

const TimeFilterDropdown = styled.select`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    border-color: ${({ theme }) => theme.primary};
  }
`;

const TimeFilterOption = styled.option`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
`;

const TimeFilter = ({ onTimeFilterChange }) => {
  const [selectedOption, setSelectedOption] = useState("last 7 days");

  const handleTimeChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    // Calculate start_time and end_time based on the selected range
    const today = new Date();
    const endTime = new Date(today.setHours(23, 59, 59, 999)); // End of today
    let startTime;

    switch (value) {
      case "last 24 hours":
        startTime = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "last 7 days":
        startTime = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "last 14 days":
        startTime = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
        break;
      case "last 30 days":
        startTime = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      default:
        startTime = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Default to last 7 days
    }

    startTime.setHours(0, 0, 0, 0); // Start of the selected range

    onTimeFilterChange({
      start_time: startTime.toISOString(),
      end_time: endTime.toISOString(),
    });
  };

  return (
    <TimeFilterContainer>
      <TimeFilterDropdown value={selectedOption} onChange={handleTimeChange}>
        <TimeFilterOption value="last 24 hours">Last 24 Hours</TimeFilterOption>
        <TimeFilterOption value="last 7 days">Last 7 Days</TimeFilterOption>
        <TimeFilterOption value="last 14 days">Last 14 Days</TimeFilterOption>
        <TimeFilterOption value="last 30 days">Last 30 Days</TimeFilterOption>
      </TimeFilterDropdown>
    </TimeFilterContainer>
  );
};

export default TimeFilter;