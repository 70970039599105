import React from 'react';
import styled from 'styled-components';
import Header from './Header'; // Assuming you have a Header component already built
import ProfileTabs from './ProfileTabs'; // Assuming ProfileTabs is where the tab content lives

// Styled components for layout
const AppContainer = styled.div`
  display: flex;
  margin-top: 60px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

function ProfileLayout({ toggleTheme, theme }) {
  return (
    <>
      <Header toggleTheme={toggleTheme} theme={theme} />
      <AppContainer>
        <ContentContainer>
          <ProfileTabs />
        </ContentContainer>
      </AppContainer>
    </>
  );
}

export default ProfileLayout;