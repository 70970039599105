import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 45%;
  padding-left: 16px;
  padding-top: 10px;
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Label = styled.label`
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const EmailInput = styled(Input)`
  background-color: ${({ theme }) => theme.inputBg}; /* Adjust to make it look like a disabled field */
  cursor: not-allowed;
`;

const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.get_color};
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBg};
  }
`;

const YourDetails = () => {
  const [organisationName, setOrganisationName] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch user data from the API when the component mounts
  useEffect(() => {
    const fetchUserProfile = async () => {
      // Retrieve token from localStorage or your storage solution
      const token = localStorage.getItem('authToken'); 

      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch('https://platform.iwander.io/external/get-user-profile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Add Bearer token to Authorization header
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user profile');
        }

        const data = await response.json();
        setOrganisationName(data.organisation_name);
        setUserName(data.user_name);
        setUserEmail(data.user_email); // Email will be non-editable
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSave = () => {
    // You can add a save logic here to update user details if needed
    console.log('Save clicked');
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading state while fetching data
  }

  return (
    <Container>
      <Label>Organisation Name</Label>
      <EmailInput
        type="text"
        value={organisationName}
        disabled
      />

      <Label>Name</Label>
      <EmailInput
        type="text"
        value={userName}
        disabled
      />

      <Label>Email address</Label>
      <EmailInput
        type="email"
        value={userEmail}
        disabled
      />

    </Container>
  );
};

export default YourDetails;