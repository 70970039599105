import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCopy, faCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import CodeBox from './CodeBox';


const Container = styled.div`
  display: flex;
  padding: 20px;
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
`;

const MainContent = styled.div`
  overflow: hidden;
  flex: 1;
  margin-right: 20px;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
`;

const SectionSubtitle = styled.h2`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.text};
  padding-top: 5%;
`;

const SectionDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.tertiary};
`;

const ApiEndpointContainer = styled.div`
  background-color: ${({ theme }) => theme.dropdownBg};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiEndpointTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const MethodTag = styled.span`
  background-color: none;
  border: 0.5px solid ${({ theme }) => theme.post_color};
  color:  ${({ theme }) => theme.post_color};
  padding: 4px 9px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 10px;
`;

const EndpointPath = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: 0.9rem;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.post_color};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;

const ApiSection = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiSectionHeader = styled.div`
  padding: 15px;
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.dropdownBorder}` : 'none')};
  background-color: ${({ isOpen, theme }) => (isOpen ? theme.dropdownBg : theme.dropdownBg)};
  color: ${({ isOpen, theme }) => (isOpen ? theme.activeText : theme.text)};
  border-radius: ${({ isOpen }) => (isOpen ? '10px 10px 0 0' : '10px')};
`;

const ApiSectionBody = styled.div`
  padding: 20px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.inputBg};
  border-radius: 0 0 10px 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
`;

const InputLabelContainer = styled.div`
  display: block;
  position: absolute;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 0.75rem;
  margin-bottom: 5px;
  width: 100%;
`;

const InputLabel = styled.label`
  background: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
`;

const InputStar = styled.label`
  padding-right: 2px;
  padding-left: 4px;
  background: ${({ theme }) => theme.inputBg};
  color: red;
`;


const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 0.75rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;


const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;

const ResponseBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
  white-space: pre-wrap; 
  overflow: auto; 
`;

const ResponseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StatusTag = styled.span`
  background-color: ${({ status, theme }) => (status >= 200 && status < 300 ? '#22C55E' : '#EF4444')};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const ResponseBody = styled.div`
  background-color: ${({ theme }) => theme.inputBg};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
`;

const ResponseText = styled.code`
  display: block;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  word-wrap: break-word;
`;

const TextGeneration = () => {
  const [headerOpen, setHeaderOpen] = useState(false);
  const [pathOpen, setPathOpen] = useState(false);
  const [guide_id, setGuide] = useState('');
  const [bodyOpen, setBodyOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [session_id, setSessionID] = useState('');
  const [user_query, setQuery] = useState('');
  const [response, setResponse] = useState([]);
  const [status, setStatus] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [bearerToken, setBearerToken] = useState('');

  const toggleSection = (section) => {
    switch (section) {
      case 'headerOpen':
        setHeaderOpen(!headerOpen);
        break;
      case 'pathOpen':
        setPathOpen(!pathOpen);
        break;
      case 'bodyOpen':
        setBodyOpen(!bodyOpen);
        break;

      default:
        break;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleApiRequest = async () => {
    try {
      const res = await fetch(`https://api.iwander.io/v1/text-generation/${guide_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearerToken}`,
        },
        body: JSON.stringify({
          lat: lat,
          lon: lng,
          session_id: session_id,
          user_query: user_query,
        }),
      });

      if (!res.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }
      else {
        setStatus(206);
      }

      const reader = res.body.getReader();
      const decoder = new TextDecoder();
      let completeResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        completeResponse += chunk;
        setResponse((prev) => [...prev, chunk]); // Ensure prev is iterable (an array)
      }
      setStatus(res.status);
    } catch (error) {
      setStatus('error');
      setResponse([{ error: 'An error occurred while making the request.' }]);
    }
  };

  const codeString = `
  curl --request POST \\
  --url https://api.iwander.io/v1/text-generation/${guide_id} \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${bearerToken}' \\
  --data '{
    "user_query": "${user_query}"
    "lat": "${lat}",
    "lon": "${lng}"
    "session_id": "${session_id}"
  }'
  `;
  return (
    <Container>
      <MainContent>
        <SectionTitle>Text Generation</SectionTitle>
        <SectionDescription>
          The Text Generation endpoint allows you to create personalized travel content for users based on their preferences and destination. 
          By leveraging this endpoint, you can generate tailored recommendations, descriptions, or travel narratives that align with each user’s unique interests. 
        </SectionDescription>
        <ApiEndpointContainer>
          <ApiEndpointTitle>
            <MethodTag>POST</MethodTag>
            <EndpointPath>
              /v1/text-generation/{'{'}guide_id{'}'}
            </EndpointPath>
            <Button onClick={handleApiRequest}><FontAwesomeIcon icon={faPaperPlane} /> Send</Button>
          </ApiEndpointTitle>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('headerOpen')} isOpen={headerOpen}>
              Header
              <FontAwesomeIcon icon={headerOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={headerOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>Bearer Token</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={bearerToken} onChange={(e) => setBearerToken(e.target.value)} placeholder="Enter Bearer Token" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('pathOpen')} isOpen={pathOpen}>
              Path
              <FontAwesomeIcon icon={pathOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={pathOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>guide_id</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={guide_id} onChange={(e) => setGuide(e.target.value)} placeholder="Enter guide_id" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('bodyOpen')} isOpen={bodyOpen}>
              Body
              <FontAwesomeIcon icon={bodyOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={bodyOpen}>
            <InputContainer>
                <InputLabelContainer>
                  <InputLabel>user_query</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={user_query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter user query" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>lat</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={lat} onChange={(e) => setLat(e.target.value)} placeholder="Enter user latitude" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>lon</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={lng} onChange={(e) => setLng(e.target.value)} placeholder="Enter user longitude" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>session_id</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={session_id} onChange={(e) => setSessionID(e.target.value)} placeholder="Enter session_id" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
        </ApiEndpointContainer>
        {response.length > 0 && (
          <ResponseBox>
            <ResponseHeader>
              <StatusTag status={status}>{status} - {status < 207 ? 'OK' : 'Error'}</StatusTag>
              <div>Body</div>
              <CopyButton onClick={() => copyToClipboard(response.join(''))}>
                <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
              </CopyButton>
            </ResponseHeader>
            <ResponseBody>
              <ResponseText>{response.join('')}</ResponseText>
            </ResponseBody>
          </ResponseBox>
        )}
        <SectionSubtitle>Instructions</SectionSubtitle>
        <SectionDescription>
          It is advised to use the /text-generation endpoint when you are not providing user's the option to stream audio from generated text output. 
        </SectionDescription>
        <SectionDescription>
          The endpoint expects a guide_id in the path, which will tailor output content to the guide persona.
        </SectionDescription>
        <SectionDescription>
          In order to initialise a new chat session, send a requqest with an empty session_id. The response will then provide you with a session_id, that
          should be sent in any subsequent requests within the same session. This is important to ensure a continuation of the conversation.
        </SectionDescription>
        <SectionDescription>
          The lat and lon values should be sent when you have access to a user's location.
        </SectionDescription>


        <div className='specs-container'>
          <div className='section-container'>
            <div className='section-title-small'>Headers</div>
            <div className='param-container'>
              <div className='param-label'>Bearer Token<div className='required-label'>*</div> </div>
              <div className='param-description'>
                The user's bearer token. This bearer token is generated upon creation of the user by calling the /create-user endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Path</div>
            <div className='param-container'>
              <div className='param-label'>guide_id  <div className='required-label'>*</div> </div>
              <div className='param-description'>
                The guide_id to be used. You can view all guide_id options by calling the /guides endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Body</div>
            <div className='param-container'>
              <div className='param-label'>user_query<div className='required-label'>*</div></div>
              <div className='param-description'>
              The user query.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>lat</div>
              <div className='param-description'>
                The user's current latitude.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>lng</div>
              <div className='param-description'>
              The user's current longitude.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>session_id</div>
              <div className='param-description'>
                The current session_id, if existing. If it is the beginning of a new interaction, send a blank session_id. The response will include a unique session_id associated to this new sessions.
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <div className="code-container">
        <div className="code-block-endpoints">
          <div className="line">
            <span className="command">curl</span>
            <span> </span>
            <span className="url">-request POST</span>
            <span> \</span>
          </div>
          <div className="line">
            <span> </span>
            <span className="url">https://api.iwander.io/v1/text-generation/{guide_id}</span>
            <span> \</span>
          </div>
          <div className="line">
            <span className="option">-H</span>
            <span> </span>
            <span className="hljs-string">"Authorization: Bearer </span>
            <span className="hljs-variable">{bearerToken}</span>
            <span className="hljs-string">"</span>
            <span> \</span>
          </div>
          <div className="line">
            <span className="option">-H</span>
            <span> </span>
            <span className="hljs-string">"Content-Type: </span>
            <span className="hljs-string">application/json</span>
            <span className="hljs-string">"</span>
          </div>
          <div className="line">
            <span className="option">-d</span>
            <span> </span>
            <span className="hljs-string">'{'{'}</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"user_query": "{user_query}",</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"lat": "{lat}",</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"lon": "{lng}",</span>
          </div>
          <div className="line">
            <span>    </span>
            <span className="hljs-string">"session_id": "{session_id}"",</span>
          </div>
          <div className="line">
            <span className="hljs-string">'{'}'}</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TextGeneration;
