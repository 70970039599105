import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false, // Allows resizing
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 0px 20px;
`;

const GraphContainer = styled.div`
  flex: 2;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const CostContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

const CostAmount = styled.p`
  font-size: 2rem;
  font-weight: bold;
  margin: 10px 0;
`;

const LimitText = styled.p`
  color: ${({ theme }) => theme.textSecondary};
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const StatsContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
`;

const PlaceholderStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
  }

  .stat-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
    .row {
    display: flex;
    text-align-last: center;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  } 

  .row p {
    margin: 0 0 0 5px; /* Add spacing between span and label */
  }
`;

const APIRequestsGraphWithCost = ({
  chartData,
  totalCost,
  chatCost,
  audioCost,
  totalChatRequests,
  totalAudioRequests,
  costPercentage,
}) => {
  if (!chartData) {
    return <div>Loading...</div>;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'API Requests Per Day',
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  const donutData = {
    labels: ['Chat', 'Audio'],
    datasets: [
      {
        data: [chatCost, audioCost],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 159, 64, 0.6)'],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Container>
      {/* Graph Section */}
      <GraphContainer>
        <SectionTitle>API Requests Per Day</SectionTitle>
        {chartData ? <Bar data={chartData} options={options} /> : <p>Loading...</p>}
      </GraphContainer>

      {/* Cost Section */}
      <CostContainer>
        <SectionTitle>Cost</SectionTitle>
        <div style={{ width: '250px', height: '250px' }}>
          <Doughnut data={donutData} />
        </div>
        <CostAmount>£{totalCost}</CostAmount>
        <StatsContainer>
          <PlaceholderStats>
            <div className="row">
              <div>
                <span className="stat-value">{totalChatRequests}</span>
                <p>Chat Requests</p>
              </div>
              <div>
                <span className="stat-value">{totalAudioRequests}</span>
                <p>Audio Requests</p>
              </div>
            </div>

          </PlaceholderStats>
        </StatsContainer>
      </CostContainer>
    </Container>
  );
};

export default APIRequestsGraphWithCost;