import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCopy, faCheck, faBolt, faArrowDown, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import CodeBox from './CodeBox';

const Container = styled.div`
  display: flex;
  padding: 20px;
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
`;

const MainContent = styled.div`
  overflow: hidden;
  flex: 1;
  margin-right: 20px;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.text};
`;

const SectionSubtitle = styled.h2`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.text};
  padding-top: 5%;
`;

const SectionDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.tertiary};
`;


const ApiEndpointContainer = styled.div`
  background-color: ${({ theme }) => theme.dropdownBg};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiEndpointTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ApiEndpointFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ResultsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 100%; 
  justify-content: center;
  column-gap: 10px;
  border-radius: 10px;
  overflow: hidden; 
  box-sizing: border-box; 
`;


const MethodTag = styled.span`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 10px;
`;

const EndpointPath = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const Button = styled.button`
background-color: ${({ theme }) => theme.get_color};
color: white;
border: none;
border-radius: 5px;
padding: 5px 10px;
font-size: 1rem;
font-weight: bold;
margin-left: auto;
cursor: pointer;
display: flex;
&:hover {
  background-color: ${({ theme }) => theme.get_hover};
}`;

const EOSButton = styled.button`
background-color: #EF4444;
color: white;
border: none;
border-radius: 5px;
padding: 5px 10px;
font-size: 1rem;
font-weight: bold;
margin-left: auto;
cursor: pointer;
display: flex;
&:hover {
  background-color: ${({ theme }) => theme.get_hover};
}`;

const ApiSection = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
`;

const ApiSectionHeader = styled.div`
  padding: 15px;
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.dropdownBorder}` : 'none')};
  background-color: ${({ isOpen, theme }) => (isOpen ? theme.dropdownBg : theme.dropdownBg)};
  color: ${({ isOpen, theme }) => (isOpen ? theme.activeText : theme.text)};
  border-radius: ${({ isOpen }) => (isOpen ? '10px 10px 0 0' : '10px')};
`;

const ApiSectionBody = styled.div`
  padding: 20px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.inputBg};
  border-radius: 0 0 10px 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
`;

const InputLabelContainer = styled.div`
  display: block;
  position: absolute;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 0.75rem;
  margin-bottom: 5px;
  width: 100%;
`;

const InputLabel = styled.label`
  background: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
`;

const InputStar = styled.label`
  padding-right: 2px;
  padding-left: 4px;
  background: ${({ theme }) => theme.inputBg};
  color: red;
`;


const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 0.75rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
`;

const ResponseBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.dropdownBg};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.dropdownBorder};
  white-space: pre-wrap; 
  overflow: auto; 
`;

const ResponseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StatusTag = styled.span`
  background-color: ${({ status, theme }) => (status == "Connected" ? '#22C55E' : '#EF4444')};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const ResponseBody = styled.div`
  background-color: ${({ theme }) => theme.inputBg};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  max-height:500px;
  overflow: scroll;
`;

const ResponseText = styled.code`
  display: block;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  word-wrap: break-word;
`;

const AudioWebsocket = () => {
  const [headerOpen, setHeaderOpen] = useState(false);
  const [pathOpen, setPathOpen] = useState(false);
  const [bodyOpen, setBodyOpen] = useState(false);
  const [textHeaderOpen, setTextHeaderOpen] = useState(false);
  const [textPathOpen, setTextPathOpen] = useState(false);
  const [textBodyOpen, setTextBodyOpen] = useState(false);
  const [session_id, setSessionID] = useState('');
  const [message_id, setMessageID] = useState('');
  const [guide_id, setGuide] = useState('');
  const [textResponse, setTextResponse] = useState([]);
  const [voice_id, setVoiceID] = useState('');
  const [user_query, setQuery] = useState('');
  const [location, setLocation] = useState('');
  const [response, setResponse] = useState(null);
  const [directAudioUrl, setDirectAudioUrl] = useState('');
  const [chatAudioUrl, setChatAudioUrl] = useState('');
  const [status, setStatus] = useState(null);
  const [audioStatus, setAudioStatus] = useState("Disconnected");
  const [isCopied, setIsCopied] = useState(false);
  const [bearerToken, setBearerToken] = useState('');
  const [websocketUrl, setWebsocketUrl] = useState('wss://api.iwander.io/v1/text-generation/{guide_id}/ws?token=');
  const [websocket, setWebsocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [audioWebsocketUrl, setAudioWebsocketUrl] = useState('wss://api.iwander.io/v1/audio-generation/{guide_id}/ws?token=');
  const [audioWebsocket, setAudioWebsocket] = useState(null);
  const [audioIsConnected, setAudioIsConnected] = useState(false);
  const audioWebsocketRef = useRef(null);
  let audio_ws = null
  let mediaSource = null;
  let sourceBuffer = null;
  const codec = "audio/mpeg";


  const toggleSection = (section) => {
    switch (section) {
      case 'headerOpen':
        setHeaderOpen(!headerOpen);
        break;
      case 'pathOpen':
        setPathOpen(!pathOpen);
        break;
      case 'bodyOpen':
        setBodyOpen(!bodyOpen);
        break;
      case 'textHeaderOpen':
        setTextHeaderOpen(!textHeaderOpen);
        break;
      case 'textPathOpen':
        setTextPathOpen(!textPathOpen);
        break;
      case 'textBodyOpen':
        setTextBodyOpen(!textBodyOpen);
        break;

      default:
        break;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  useEffect(() => {
    if (audioIsConnected) {
      console.log('Audio WebSocket connected (useEffect):', audioIsConnected);
    }
  }, [audioIsConnected]);

  useEffect(() => {
    const url = (bearerToken && guide_id)
      ? `wss://api.iwander.io/v1/audio-generation/${guide_id}/ws?token=${bearerToken}`
      : `wss://api.iwander.io/v1/audio-generation/{guide_id}/ws?token=${bearerToken}`;

    setAudioWebsocketUrl(url);
  }, [bearerToken, guide_id]);

  useEffect(() => {
    const url = (bearerToken && guide_id)
      ? `wss://api.iwander.io/v1/text-generation/${guide_id}/ws?token=${bearerToken}`
      : `wss://api.iwander.io/v1/text-generation/{guide_id}/ws?token=${bearerToken}`;

    setWebsocketUrl(url);
  }, [bearerToken, guide_id]);


  const sendAudioWebSocketMessage = () => {
    if (audioIsConnected) {
      audioWebsocket.send(JSON.stringify({ "message_id": message_id }));
      audioWebsocket.send(JSON.stringify({ "text": user_query }));
    }
  };

  const endWebsocketMessage = () => {
    if (audioIsConnected) {
      audioWebsocket.send(JSON.stringify({ 'text': "Last Chunk Sent" }));
    }
  };

  const handleAudioWebSocketConnect = (context) => {
    if (audioIsConnected) {
      if (audioWebsocketRef.current) {
        audioWebsocketRef.current.close();
        audioWebsocketRef.current = null;
        console.log("Audio WebSocket disconnected");
        setAudioIsConnected(false);
        setAudioStatus('Disconnected');
      }
    } else {
      audio_ws = new WebSocket(audioWebsocketUrl);
      console.log('Creating new Audio WebSocket:', audio_ws);

      audio_ws.onopen = () => {
        audioWebsocketRef.current = audio_ws;
        setAudioIsConnected(true);
        setAudioStatus('Connected');
        setAudioWebsocket(audio_ws);
        console.log('Audio WebSocket connected');

        mediaSource = new MediaSource();
        const audioUrl = URL.createObjectURL(mediaSource);
        if (context === 'direct') {
          setDirectAudioUrl(audioUrl);
        } else if (context === 'chat') {
          setChatAudioUrl(audioUrl);
        }

        mediaSource.addEventListener('sourceopen', () => {
          sourceBuffer = mediaSource.addSourceBuffer(codec);
        });

        // Ensure sections are correctly toggled
        if (headerOpen) toggleSection('headerOpen');
        if (pathOpen) toggleSection('pathOpen');
        if (!bodyOpen) toggleSection('bodyOpen');
      };

      audio_ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        console.log('Received from audio WebSocket:', response);
        if (response.data && sourceBuffer && !sourceBuffer.updating) {
          const audioData = new Uint8Array(atob(response.data).split("").map(char => char.charCodeAt(0)));
          sourceBuffer.appendBuffer(audioData);
        }
        setAudioStatus(response.status);
      };

      audio_ws.onerror = (error) => {
        setAudioStatus('Error');
        console.error('Audio WebSocket error:', error);
      };

      audio_ws.onclose = () => {
        setAudioIsConnected(false);
        setAudioStatus('Disconnected');
        console.log('Audio WebSocket disconnected');
      };
    }
  };

  const handleWebSocketConnect = () => {
    if (isConnected) {
      if (websocket) {
        websocket.close();
        setWebsocket(null);
        setIsConnected(false);
        setStatus('Disconnected');
      }
    } else {
      const ws = new WebSocket(websocketUrl);
      let textStream = "";

      ws.onopen = () => {
        setIsConnected(true);
        setStatus('Connected');
        console.log('WebSocket connected');
      };

      ws.onmessage = (event) => {
        const message = event.data;
        setTextResponse((prev) => [...prev, message]);
        console.log('Received from text WebSocket:', message);
        const data = JSON.parse(message);

        if (data.type === "message_id") {
          console.log('Message ID received:', data.data);
          audio_ws.send(JSON.stringify({ "message_id": data.data }))
        }

        if (data.type === "text") {
          textStream += data.data;
          console.log('Text chunk received:', data.data);
          audio_ws.send(JSON.stringify({ "text": data.data }))

          if (data.data === "" && textStream.trim().length > 0) {
            audio_ws.send(JSON.stringify({ "text": "Last Chunk Sent" }))
          }
        }
      };

      ws.onerror = (error) => {
        setStatus('Error');
        console.error('Text WebSocket error:', error);
      };

      ws.onclose = () => {
        setIsConnected(false);
        setStatus('Disconnected');
        console.log('Text WebSocket disconnected');
      };

      setWebsocket(ws);
    }
  };


  const sendWebSocketMessage = () => {
    if (isConnected) {
      const messageData = {
        session_id: session_id,
        text: user_query,
        location: "51.488075 -0.105049"
      };
      websocket.send(JSON.stringify(messageData));
    }
  };

  return (
    <Container>
      <MainContent>
        <SectionTitle>Audio Generation</SectionTitle>
        <SectionDescription>
          This API allows you to generate audio using websockets. This websocket is designed to create audio in real-time with a text-stream input.
          It is recommended to only use this websocket when the input text is a stream.
        </SectionDescription>
        <ApiEndpointContainer>
          <ApiEndpointTitle>
            <FontAwesomeIcon icon={faBolt} style={{ marginRight: '10px', minWidth: '35px', textAlign: 'center', paddingRight: '5px', paddingLeft: '5px', color: audioIsConnected ? '#2ab673' : 'white' }} />
            <EndpointPath>
              {audioWebsocketUrl}
            </EndpointPath>
            <Button onClick={() => handleAudioWebSocketConnect('direct')}
            ><FontAwesomeIcon icon={faBolt} style={{ marginRight: '5px' }} />{audioIsConnected ? ' Disconnect' : ' Connect'}</Button>
          </ApiEndpointTitle>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('headerOpen')} isOpen={headerOpen}>
              Query
              <FontAwesomeIcon icon={headerOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={headerOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>Bearer Token</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={bearerToken} onChange={(e) => setBearerToken(e.target.value)} placeholder="Enter Bearer Token" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('pathOpen')} isOpen={pathOpen}>
              Path
              <FontAwesomeIcon icon={pathOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={pathOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>guide_id:</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={guide_id} onChange={(e) => setGuide(e.target.value)} placeholder="Enter guide_id" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiSection>
            <ApiSectionHeader onClick={() => toggleSection('bodyOpen')} isOpen={bodyOpen}>
              Body
              <FontAwesomeIcon icon={bodyOpen ? faChevronUp : faChevronDown} />
            </ApiSectionHeader>
            <ApiSectionBody isOpen={bodyOpen}>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>text</InputLabel>
                  <InputStar>*</InputStar>
                </InputLabelContainer>
                <Input type="text" value={user_query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter user query" />
              </InputContainer>
              <InputContainer>
                <InputLabelContainer>
                  <InputLabel>message_id</InputLabel>
                </InputLabelContainer>
                <Input type="text" value={message_id} onChange={(e) => setMessageID(e.target.value)} placeholder="Enter message_id" />
              </InputContainer>
            </ApiSectionBody>
          </ApiSection>
          <ApiEndpointFooter>
            <EOSButton onClick={endWebsocketMessage}>End Stream</EOSButton>
            <Button onClick={sendAudioWebSocketMessage}><FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} /> Send</Button>

          </ApiEndpointFooter>
        </ApiEndpointContainer>
        <SectionSubtitle>Instructions</SectionSubtitle>
        <SectionDescription>
          It is advised to use the audio websocket only for text stream to audio stream purposes.
        </SectionDescription>
        <SectionDescription>
          The endpoint expects a guide_id in the path, which will decide the voice of the audio output.
        </SectionDescription>
        <SectionDescription>
          Chunking is handled server-side, so feel free to send over every chunk of text as it is received individually.
          Once all text has been sent, the server expects the following json: {'{'}"text": "Last Chunk Sent" {'}'} to signify all the text has been sent.
        </SectionDescription>
        <SectionDescription>
          The End Stream button above mimics the behaviour of sending "Last Chunk Sent" to the audio websocket.
        </SectionDescription>


        <div className='specs-container'>
          <div className='section-container'>
            <div className='section-title-small'>Headers</div>
            <div className='param-container'>
              <div className='param-label'>Bearer Token<div className='required-label'>*</div> </div>
              <div className='param-description'>
                The user's bearer token. This bearer token is generated upon creation of the user by calling the /create-user endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Path</div>
            <div className='param-container'>
              <div className='param-label'>guide_id  <div className='required-label'>*</div> </div>
              <div className='param-description'>
                The guide_id to be used. You can view all guide_id options by calling the /guides endpoint.
              </div>
            </div>
          </div>
          <div className='section-container'>
            <div className='section-title-small'>Body</div>
            <div className='param-container'>
              <div className='param-label'>text<div className='required-label'>*</div></div>
              <div className='param-description'>
                The text to convert into audio.
              </div>
            </div>
            <div className='param-container'>
              <div className='param-label'>message_id</div>
              <div className='param-description'>
                The message_id, if applicable. This can be used to retrieve historical audios.
              </div>
            </div>
          </div>
        </div>


      </MainContent>
      <div className="ws-container">
        <SectionSubtitle>Response Sample</SectionSubtitle>

        {directAudioUrl && (
          <ResponseBox>
            <ResponseHeader>
              <StatusTag status={audioStatus}>{audioStatus}</StatusTag>
              <div>Body</div>
              <CopyButton onClick={() => copyToClipboard(JSON.stringify(response, null, 2))}>
                <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
              </CopyButton>
            </ResponseHeader>
            <ResponseBody>
              <audio controls>
                <source src={directAudioUrl} type="audio/mpeg" />
              </audio>
            </ResponseBody>
          </ResponseBox>
        )}
      </div>
    </Container>
  );
};

export default AudioWebsocket;