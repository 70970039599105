import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import { lightTheme, darkTheme } from './themes';
import DocsLayout from './components/DocsLayout';
import AccountPage from './components/AccountPage';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';

const AppContainer = styled.div`
  display: flex;
  margin-top: 60px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

function App() {
  const [theme, setTheme] = useState('dark');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state to wait for token check

  // Check if the user is authenticated when the app first loads
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true); // If there's a token, consider the user authenticated
    }
    setLoading(false); // Stop the loading state after the check
  }, []);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const handleLogin = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true); // Set the user as authenticated upon login
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false); // Log the user out by removing the token
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking token
  }

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <>
        <GlobalStyle />
        <Router>
          <Routes>
            {/* Login Route */}
            <Route path="/login" element={<Login onLogin={handleLogin} />} />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Dashboard toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />
            {/* Protected Routes */}
            <Route
              path="/docs/api-reference/*"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DocsLayout toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/*"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <AccountPage toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />

            {/* Redirect all other routes to login if not authenticated */}
            <Route path="*" element={<Navigate to={isAuthenticated ? "/docs/api-reference" : "/login"} />} />
          </Routes>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;