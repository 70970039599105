import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-style: solid;
  position: fixed;
  height: 5.5vh;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.img`
  display: flex;
  align-items: center;
  margin-left: 25px;
  font-size: 1.5rem;
  height: 50px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 10px;

  &:hover {
    color: ${({ theme }) => theme.post_color};
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.post_color};
  }
`;

const LogoutButton = styled(Button)`
  margin-left: 20px;
`;

const Header = ({ toggleTheme, theme }) => {
  const navigate = useNavigate();

  // Handle logout functionality
  const handleLogout = () => {
    sessionStorage.removeItem('authToken'); 
    localStorage.removeItem('authToken'); 

    navigate('/login');
  };

  const logoSrc =
    theme === 'dark'
      ? `${process.env.PUBLIC_URL}/images/logo_white.png`
      : `${process.env.PUBLIC_URL}/images/logo_black.png`;

  return (
    <HeaderContainer>
      {/* Logo on the left */}
      <Logo src={logoSrc} alt="Logo" />

      {/* Links and theme toggle button on the right */}
      <RightContainer>
        <NavLinks>
          <NavLink to="/dashboard">Dashboard</NavLink>
          <NavLink to="/docs/api-reference/introduction">API Reference</NavLink>
          <NavLink to="/account?tab=profile">Account</NavLink>
        </NavLinks>

        {/* Theme toggle button */}
        <Button onClick={toggleTheme}>
          <FontAwesomeIcon icon={theme === 'dark' ? faSun : faMoon} />
        </Button>

        {/* Logout button with exit icon */}
        <LogoutButton onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </LogoutButton>
      </RightContainer>
    </HeaderContainer>
  );
};

export default Header;